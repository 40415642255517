.savingPotentielTable>thead>tr{
    background-color: #ffffff;
}
.savingPotentielTable >tbody>tr{
    padding: 1.6rem 2.4rem;
    height:5.2rem;
}
.savingPotentielTable >tbody>tr:nth-child(odd){
    background: #F9FCFB;
border-bottom: 1px solid #EBEBEB;
}
.savingPotentielTable >tbody>tr>td{
    border: 0 !important;
    text-align: left!important;
    font-size: 1.4rem!important;
    font-family:'Lato-Regular'!important;
}
.savingPotentielTable >tbody>tr>td:last-child,.savingPotentielTable >thead>tr>th:last-child{
    text-align: right !important;
}

.month-picker.custom > .rmp-container .rmp-popup.light {
    color: #1f1f1f;
    background-color: #f5f5f5 !important;
    width: 28rem !important;
    height: 22.5rem;
    left: 0rem;
}

.month-picker.custom > .rmp-container .rmp-popup .rmp-pad{
    width:28rem !important;
}
.month-picker.custom > .rmp-container .rmp-popup.light.range .rmp-pad{

	width:28rem !important;
   
    background-color: #f5f5f5 !important;
}
.month-picker.custom > .rmp-container .rmp-popup .rmp-pad > div {
	display: flex;
    align-items: center;
    justify-content: center;
	
}
.month-picker.custom > .rmp-container .rmp-popup .rmp-pad > div label{
	background-color: #FFFFFF !important;

	width:14rem !important;
	height: 3.9rem !important;
	font-family: 'Lato-Bold' !important;
	color: #1f1f1f !important;
}
.month-picker.custom > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn{
	background-color: #FFFFFF ;
	color: #1f1f1f !important;
	font-size: 1.6rem;
	font-family: 'Lato-Medium' !important;
	
}

.month-picker.custom > .rmp-container .rmp-popup .rmp-pad li{
	font-family: 'Lato-Medium' !important;
	width: 32% !important;
    margin: .2% !important;
}

.month-picker.custom > .rmp-container .rmp-popup .rmp-pad > div i.prev {

	width: 3.5rem;
    height: 3.5rem;
   
    /* top: calc(50% - 38.31px/2 - 0.15px); */
    background: #FFFFFF;
    box-shadow: 0px 0.870647px 0.870647px rgb(0 14 51 / 5%) !important;
    border-radius: 6.9rem !important;
    font-size: 2rem !important;
    font-family: 'Lato-Bold' !important;
}
.month-picker.custom > .rmp-container .rmp-popup .rmp-pad > div i.next{
	width: 3.5rem;
    height: 3.5rem;
   
    /* top: calc(50% - 38.31px/2 - 0.15px); */
    background: #FFFFFF;
    box-shadow: 0px 0.870647px 0.870647px rgb(0 14 51 / 5%);
    border-radius: 6.9rem !important;
    font-size: 2rem !important;
    font-family: 'Lato-Bold' !important;
}
.month-picker.custom > .rmp-container .rmp-popup .rmp-pad ul{
	margin-top: 1rem;
}

.month-picker.custom > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker.custom > .rmp-container .rmp-popup.light .rmp-pad li.active:hover{
	background: #4B8499 !important;
box-shadow: 0px 1.30597px 0.870647px rgba(0, 31, 112, 0.25), 0px 2.61194px 1.74129px rgba(0, 71, 255, 0.2);
border-radius: .5rem;
}

.month-picker.custom > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select{
	
background: #91CDE3!important;
color: #4B8499;
border-radius: .5rem; 
}


.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover{
    background-color: transparent!important;
    color: #bbb!important;
    cursor: default;
}
.download-button{
    margin-right: 1rem!important;
}
.top-content{
    display: flex;
    margin-left: 1rem;
    /* width: 15rem; */
}
.sp-download-dialog .MuiFormControlLabel-label {
    position: static !important;
    width: auto !important;
    font-family: 'Lato-Regular' !important;
    display: inline;  
  }