* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	text-decoration: none;
	line-height: 1.2;
}

html {
	font-size: 10px;
}

@media (max-width: 1300px) {
	html {
		font-size: 8px;
	}
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-left: 0;
	font-weight: normal;
	color:var(--c1) !important;
}
a[href=""],
a[href="javascript:void(0)"] {
	cursor: default;
}
body {
	background: rgba(255, 255, 255, 0.96);
	width: 100%;
	height: 100%;
	text-rendering: optimizeLegibility;
	-webkit-font-feature-settings: "kern";
	font-feature-settings: "kern";
	font-kerning: normal;
	font-variant-ligatures: common-ligatures;
	font: normal 14px/1.4 Lato-Regular, Arial, Helvetica, sans-serif !important;
	color: var(--c1);
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}
/*Application font color  and font size*/
:root {
	--c1: #000000;
	--c2: #3f3f3f;
	--c3: #555555;
	--c4: #2b3137;
	--c5: #969696;
	--c6: #ffffff;
	--c7: #00bcff;
	--c8:#008CBA ;
}

/*Application font*/

@font-face {
	font-family: "Lato-Regular";
	src: url("./Fonts/Lato-Regular.ttf") format("truetype");
	src: url(./Fonts/Lato-Black.eot);
	src: local(":P"), url(./Fonts/Lato-Regular.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-Regular.woff) format("woff"), url(./Fonts/Lato-Regular.ttf) format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Lato-Black";
	src: url("./Fonts/Lato-Black.ttf") format("truetype");
	src: url(./Fonts/Lato-Black.eot);
	src: local(":P"), url(./Fonts/Lato-Black.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-Black.woff) format("woff"), url(./Fonts/Lato-Black.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Lato-Bold";
	src: url("./Fonts/Lato-Bold.ttf") format("truetype");
	src: url(./Fonts/Lato-Bold.eot);
	src: local(":P"), url(./Fonts/Lato-Bold.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-Bold.woff) format("woff"), url(./Fonts/Lato-Bold.ttf) format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Lato-Semibold";
	src: url("./Fonts/Lato-Semibold.ttf") format("truetype");
	src: url(./Fonts/Lato-Semibold.eot);
	src: local(":P"), url(./Fonts/Lato-Semibold.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-Semibold.woff) format("woff"), url(./Fonts/Lato-Semibold.ttf) format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Lato-Medium";
	src: url("./Fonts/Lato-Medium.ttf") format("truetype");
	src: url(./Fonts/Lato-Medium.eot);
	src: local(":P"), url(./Fonts/Lato-Medium.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-Medium.woff) format("woff"), url(./Fonts/Lato-Medium.ttf) format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Lato-Light";
	src: url("./Fonts/Lato-Light.ttf") format("truetype");
	src: url(./Fonts/Lato-Light.eot);
	src: local(":P"), url(./Fonts/Lato-Light.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-Light.woff) format("woff"), url(./Fonts/Lato-Light.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Lato-MediumItalic";
	src: url("./Fonts/Lato-MediumItalic.ttf") format("truetype");
	src: url(./Fonts/Lato-MediumItalic.eot);
	src: local(":P"), url(./Fonts/Lato-MediumItalic.eot?#iefix) format("embedded-opentype"),
		url(./Fonts/Lato-MediumItalic.woff) format("woff"), url(./Fonts/Lato-MediumItalic.ttf) format("truetype");
	font-weight: normal;
	font-style: italic;
}

/* end*/
.wrapper {
	min-height: 100vh;
	width: 100%;
	background-color: var(--c6);
	overflow-x: hidden;
}

.wrapper_inner_width {
	
	margin: 0 2.4rem;
}

.main-Content {
	margin-top: 8.3rem;
	height: auto;
	min-height: calc(100vh - 8.3rem);
}

.filterBar {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.6rem;
	height: 8.3rem;
	background-color: var(--c6);
	position: fixed;
	z-index: 1000;
	
}

.boxFlex {
	display: flex;
	align-items: center;
	margin: 0 5px;
	justify-content: center;
}
.scenarioContainer {
	height: 9.3rem;
	display: flex;
	align-items: center;
	padding: 2.4rem 0;
	border-bottom: 2px solid #ebebeb;
}

.textField {
	background:var(--c6);
	border-bottom: 2px solid #ebebeb;
	width: 100%;
	height: 4.8rem;
}

.css-1tcs2i1-MuiInputBase-root-MuiInput-root {
	width: 100%;
	font-family: "Lato-Medium";
	font-size: 3.2rem;
	border: 0 !important;
}
.css-1tcs2i1-MuiInputBase-root-MuiInput-root:before {
	border: 0 !important;
}
.css-1tcs2i1-MuiInputBase-root-MuiInput-root:after {
	border: 0 !important;
}

.scenarioDashboardContainer {
	width: 100%;
	height: max-content;
	display: flex;
	overflow: hidden;
}
.chartContainer {
	padding-top: 3.1rem;
}

.title_1 {
	font-family: "Lato-Medium";
	font-size: 3.2rem;
	line-height: 4.2rem;
	color: var(--c3);
}
.title_2 {
	font-family: "Lato-SemiBold";
	font-size: 2rem;
	line-height: 2.4rem;
	color: var(--c1);
}
.title_3 {
	font-family: "Lato-SemiBold";
	font-size: 1.6rem;
	line-height: 2rem;
	color: var(--c1);
}
.title_4 {
	font-family: "Lato-Regular";
	font-size: 1.4rem;
	line-height: 2rem;
	color: var(--c1);
}

.title_5 {
	font-family: "Lato-SemiBold";
	font-size: 1.4rem;
	line-height: 2rem;
}
.title_6 {
	font-family: "Lato-SemiBold";
	font-size: 1.2rem;
	line-height: 1.6rem;
}

.title_7 {
	font-family: "Lato-Medium";
	font-size: 1.6rem;
	line-height: 2rem;
	color: var(--c3);
}
.dataContainer {
	background: var(--c6);
	border-left: 2px solid #ebebeb;
	padding-top: 3.1rem;
	width: 35%;
}

.button {
	display: flex !important;
	flex-direction: row !important;
	justify-content: center !important;
	align-items: center !important;
	padding: 1.4rem 1.2rem !important;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12) !important;
	border-radius: 4px !important;
	font-family: "Lato-Medium" !important;
	font-size: 1.4rem !important;
	line-height: 1.4rem !important;
	border: 0 !important;
	cursor: pointer;
}
.cancel,.preview {
	background-color: var(--c6) !important;
	color: var(--c1) ;
}

.save {
	background-color:var(--c1);
	color:var(--c6)!important ;
}

.load{
	font-size: 1.4rem !important;
	line-height: 2.4rem !important;
	padding: 1.6rem;
	background-color:var(--c8)!important;
	color:var(--c6)!important ;
	
}

.cursor {
	cursor: pointer;
}

.editPicker {
	width: 18rem;
	background: #fafafa;
	border: 1px solid #e6e6e6;
	border-radius: 4px;
	height: 4.5rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: "Lato-Regular";
	font-size: 1.4rem;
	line-height: 1.7rem;
	margin-left: .5rem;
}
/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 12px;
}

.rect:hover {
	transform: scaleY(1);
	width: 9%;
	translate: -5px;
	transition: all 0.2s ease;
	clip-path: inset(-1% -1% 1% round 5px);
}
/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
	border-radius: 10px;
}

.dropdownContent {
	width: 57rem;
	height: 43rem;
	background-color: var(--c6);
	border: 1px solid #d3d3d3;
	filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.18));
	border-radius: 4px;
	position: relative;
	left: -30rem;
	top: 1rem;
	z-index: 101;
}
.report-style-class {
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	background: none;
}

.report-style-class iframe {
	border: 1px solid #c8c8c8;
}

.css-b62m3t-container{
	width:16rem !important;
	margin: 0rem;
  }

  .css-1ljfigo-MuiStack-root>:not(style)+:not(style){
	margin: 0;
    margin-left: .5rem!important
  }
  .css-dk3kx5-container{
	width:19rem !important
  }

  .filterBarParent{
	position: relative;
	height:8.3rem;
	margin-top: 8.3rem;
	
  }

  .warning{
	font-size: 1.6rem;
    font-family: "Lato-Bold";
    color: red;
    height: 120px;
    /* display: flex; */
    width: 90%;
  }