/* LandingPage.css */
.landing-page {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8.3rem;
}
.dashboard-sections {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two items per row */
  gap: 20px;
  padding: 20px;
}

.section-link {
  text-decoration: none;
  color: inherit;
  max-width: 600px;
}
.section-item {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  /* New border for style */
  border: 4px solid #3F3F3F; /* Light gray border */
}

.section-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-color: #007BFF; /* Border color changes on hover */
}
.section-image {
  /* width: 80px;
  height: 80px; */
  object-fit: contain;
  margin-right: 20px;
  
  max-height: 100%;
    max-width: 100%;
    display: block;
    margin-bottom: .5rem;
    /* background: #c7c7c7; */
    width: 200px;
    aspect-ratio: 418 / 260;
}

.section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-content h3 {
  margin: 0;
  color: #3F3F3F;
  font-family: 'Lato-Bold';
  font-size: 2.4rem;
}

.section-content p {
  margin: 5px 0 0;
  color: #3F3F3F;
  font-family: 'Lato-Regular';
  font-size: 1.8rem;
}


  
  