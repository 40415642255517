.navbar{
    height:8.3rem;
    background-color: #F9F9F9 !important;
    display: flex;
    align-items: center;
    box-shadow: none !important;
}
.logoContainer{
    padding-left: 2rem !important;
    background: transparent !important;
}
.companylogo{
    width:14.9rem;
    height:5.1rem;
}
.navLinkContainer{
    background: transparent !important ;
    border-radius: 0 !important;
    height: 4.2rem;
    
}
.navLinkContainer:hover{
    border-bottom: #1A7AEA 2px solid!important;
}

.navLink{
    font-family: 'Lato-Regular';
    color:#3F3F3F;
    text-transform: uppercase;
    font-size: 1.4rem ;
    letter-spacing: 0.04rem;
    line-height: 1.6rem;

}
.navLink:hover{
    color:#1A7AEA !important;
}



.profileDrawerContainerDiv{
    width:27rem;
    position: absolute;
    top:8.5rem;
    height: 7rem; 
    background-color: #FFFFFF;
    border: 1px solid #D3D3D3;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.18));
    border-radius: 4px;  

}
.profileContainerDiv{
    position: relative;
    width: 24.4rem;
    background: transparent !important;
    background: #F3F3F3;
    border-left: 2px solid #E6E6E6;
    height: 8.3rem;
    display: flex;
    z-index: 1000;

}
.proflieText{
   color:#3F3F3F;
   font-family: 'Lato-Regular';
   font-size: 1.4rem; 
   margin-left: 1rem;
   text-align:justify;
}

.logOut:hover,.AvatarLink:hover{
    background-color:transparent;
}
.logout:focus,.AvatarLink:focus
{
    background-color:transparent;
}

.AppName{
    color:#3F3F3F;
    font-family: 'Lato-Bold';
    font-size: 2.4rem; 
    
    /*  text-align:justify;
   cursor: none; */
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input {
    left: 100%;
    width: 0px !important;
}
.css-ahj2mt-MuiTypography-root {
    color: #3F3F3F;
    font-family: 'Lato-Regular'!important;
    font-size: 1.2rem !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    position: absolute;
    width: 80px;
    top: 35px;
}
.css-1jaw3da > .MuiSwitch-switchBase >.MuiSwitch-input{

    left: 100%;

    width: 0px !important;

}

 

.css-1jaw3da >.MuiFormControlLabel-label {

    color: #3F3F3F;

    font-family: 'Lato-Regular'!important;

    font-size: 1.2rem !important;

    line-height: 1.5;

    letter-spacing: 0.00938em;

    position: absolute;

    width: 80px;

    top: 35px;

}
.css-1nr2wod .MuiSwitch-input {
    left: 100%;
    width: 0px !important; 
}
  
  
  
  
  
  