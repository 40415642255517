.modal{
    position: fixed;
    left:0%;
    top:0%;
    right:0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-self: center;
    z-index: 1111;
}


.modal-content{
    min-width: 45rem;
    max-width: 80rem;
    background-color: #fff;
    position: relative;
    left: 35%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
border: 1px solid #D3D3D3;
/* cta shadow */

box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
border-radius: 4px;
}

.modal-header, .modal-footer{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-title{
    margin: 0;
}

.modal-body{
    padding: 10px;
    min-height: 10rem;
    height: auto;
    display: flex;
    align-items: center;
 
}
.addlinebreak {
    white-space: pre-line;
  }
