.customizeNormContainer{
  width:100%;
 
 }
 .customNormContainer{
     position: relative;
     width: 100%;
     left: 0;
 }
 .collapsibleButton,.buttonBar{
     height:6.1rem;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 1.6rem 2.4rem;
 width: 100%;
 
 background: #EBF4FB;;
 border-bottom: 2px solid #EBEBEB;
     
 }
 .customNorm_inner{
     background-color: #ffffff;
 }
 .customNorm_innerContainer{
    position: relative;
    box-shadow: 0px -7px 28px rgba(0, 0, 0, 0.14);
 }
 
 .divDown{
     height:6.1rem;
     transition: all 1s ease;
 }
 .divUp{
     /*top:calc(100% - 35.3rem);*/
     display: block;
     transition: bottom 1s ease;
 }
 
 .buttonBar{
     display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 1.6rem 2.4rem;
 background: #F1F1F1;
 border-bottom: 2px solid #EBEBEB;
 }
 
 
 .draggbleFilterContainer{
     display: flex;
     padding: 1rem;
 }
 .draggbleZone{
     display: flex;
     border: 1px dashed (--c7);
     width: calc(100vw - 27rem);
 }
 .dragbar_ButtonArea{
     display: flex;
     flex-direction: row;
     margin-left: 4rem;
     align-items: center;
     width: 27rem;
     justify-content: end;
 }
 .resetButton{
     color: #1F7DEB !important;
     box-shadow: none !important;
 }
 .refreshButton{
     color:#000000 !important;
     
     box-shadow: none !important;
 }
 .customNorm_tableContainer{
     display: flex;
     width:100%;
     height: 60.3rem;
     background-color: #ffffff;
 }
 .customNorm_tableInnerContainer{
     display: flex;
     overflow: auto;
     flex-direction: column;
 }
 
 .defineNormArrow_black{
     height:1.8rem;
     width:2rem;
 }
 .defineNormArrow_blue{
     height:1.3rem;
     width:1.4rem;
 }
 .defineText{
     font-family: 'Lato-SemiBold';
     font-size: 1.8rem;
     line-height: 2.1rem;
     margin-left: .8rem;
 }
 .previewIcon{
 height:1.2rem;
 width:1.6rem;
 margin-right: .6rem;
 }
 .saveIcon{
     height:1.6rem;
 width:1.6rem;
 margin-right: .6rem;
 }
 
 .tree ul {
   margin-left: 20px;
 }
 
 .tree li {
   list-style-type: none;
   margin:0 0 0 0px;
   position: relative;
 }
 .tree li>span:first-child{
   padding-left: 20px;
 }
 .subChild3 ul{
   position: relative;
 }
 .subChild3 ul::before {
   position:absolute !important;
   content:"";
   top:-18px;
   left: -32px !important;;
   width: 20px !important;;
   border-left: 1px solid #ccc !important;
   height: 100% !important;;
   display: block;
 }
 .subChild2 ul{
   position: relative;
 }
 .subChild2 ul::before {
   position:absolute !important;
   content:"";
   top:-18px;
   left: -32px !important;;
   width: 20px !important;;
   border-left: 1px solid #ccc !important;
   height: 100% !important;;
   display: block;
 }
 
 .subChild1 ul{
   position: relative;
 }
 .subChild1 ul::before {
   position:absolute !important;
   content:"";
   top:-18px;
   left: -32px !important;;
   width: 20px !important;;
   border-left: 1px solid #ccc !important;
   height: 100% !important;;
   display: block;
 }
 .notExpandable ul{
   position: relative;
 }
 .notExpandable ul::before {
   position:absolute !important;
   content:"";
   top:-18px;
   left: -32px !important;;
   width: 20px !important;;
   border-left: 1px solid #ccc !important;
   height: 100% !important;;
   display: block;
 }
 
 .subChild1 ul:last-child:before{
   display: none;
 }
 
 .subChild2 ul:last-child:before{
   display: none;
 }
 .subChild2 ul:last-child:before{
   display: none;
 }
 .subChild4 ul:last-child:before{
   display: none;
 }
 
 .tree li::after {
   position:absolute;
   content:url("../../../public/AppAssets/collapse.svg");
   top:8px;
   left:-2px;
   width:20px;
   height:100%;
   z-index: 11;
 }
 
 .notExpandable>li::before{
   content: "" !important;
   position: absolute !important;
   top: -18px!important;
   left: -12px!important;
   border-left: 1px solid #ccc !important;
   border-bottom: 1px solid #ccc !important;
   border-radius: 0 0 0 0px !important;
   width: 25px !important;
   height: 35px !important;
   z-index: 9;
 }
 
 .notExpandable>li::after{
   content:""!important;
   display: block;
   position: absolute !important;
   top: 8px!important;
   left: -2px!important;
   width: 20px !important;
   height: 25px !important;
   z-index: 11;
 }
 
 .subChild1 >li::before {
   content: "";
   position: absolute !important;
   top: -18px!important;
   left: -12px!important;
   border-left: 1px solid #ccc !important;
   border-bottom: 1px solid #ccc !important;
   border-radius: 0 0 0 0px !important;
   width: 10px !important;
   height: 35px !important;
   z-index: 9;
 }
 .subChild1 >li::after {
   content:url("../../../public/AppAssets/collapse.svg");
   display: block;
   position: absolute !important;
   top: 8px!important;
   left: -2px!important;
   width: 20px !important;
   height: 25px !important;
   z-index: 11;
 
 }
 
 .subChild2 >li::before {
   content: "";
   position: absolute !important;
   top: -18px!important;
   left: -12px!important;
   border-left: 1px solid #ccc !important;
   border-bottom: 1px solid #ccc !important;
   border-radius: 0 0 0 0px !important;
   width: 10px !important;
   height: 35px !important;
   z-index: 9;
 }
 .subChild2 >li::after {
   content:url("../../../public/AppAssets/collapse.svg");
   display: block;
   position: absolute !important;
   top: 8px!important;
   left: -2px!important;
   width: 20px !important;
   height: 25px !important;
   z-index: 11;
 }
 
 
 .subChild3 >li::before {
   content: "";
   position: absolute !important;
   top: -18px!important;
   left: -12px!important;
   border-left: 1px solid #ccc !important;
   border-bottom: 1px solid #ccc !important;
   border-radius: 0 0 0 0px !important;
   width: 10px !important;
   height: 35px !important;
   z-index: 9;
 }
 .subChild3 >li::after {
   content:url("../../../public/AppAssets/collapse.svg");
   display: block;
   position: absolute !important;
   top: 8px!important;
   left: -2px!important;
   width: 20px !important;
   height: 25px !important;
   z-index: 11;
 }
 .subChild4 >li::before {
   content: "";
   position: absolute !important;
   top: -18px!important;
   left: -12px!important;
   border-left: 1px solid #ccc !important;
   border-bottom: 1px solid #ccc !important;
   border-radius: 0 0 0 0px !important;
   width: 10px !important;
   height: 35px !important;
   z-index: 9;
 }
 
 
 
 
 
 
 .treeParentSpanStyle{
   width:35.8rem !important;
   position: relative;
   z-index: 13;
       
 }
 
 @media only screen and (min-width: 1070px) {
 .treeChildSpanStyle{
   width:33.3rem !important;
   position: relative;
   z-index: 13;
 }
 .treeChild2SpanStyle{
   width:30.8rem!important;
   position: relative;
   z-index: 13;
 }
 
 .treeChild3SpanStyle{
   width:28.3rem !important;
   position: relative;
   z-index: 13;
 }
 .treeChild4SpanStyle{
   width:25.8rem!important;
   position: relative;
   z-index: 13;
 }
 
 }
 
 @media only screen and (min-width: 1300px) {
   .treeChildSpanStyle{
     width:33.8rem !important;
     position: relative;
   z-index: 13;
   }
   .treeChild2SpanStyle{
     width:31.8rem!important;
     position: relative;
   z-index: 13;
   }
   
   .treeChild3SpanStyle{
     width:29.8rem !important;
     position: relative;
   z-index: 13;
   }
   .treeChild4SpanStyle{
     width:27.8rem!important;
     position: relative;
   z-index: 13;
   }
   
   }
 
 
 @media only screen and (min-width: 1500px) {
   .treeChildSpanStyle{
     width:33.8rem !important;
     position: relative;
   z-index: 13;
   }
   .treeChild2SpanStyle{
     width:31.8rem!important;
     position: relative;
   z-index: 13;
   }
   
   .treeChild3SpanStyle{
     width:29.8rem !important;
     position: relative;
   z-index: 13;
   }
   .treeChild4SpanStyle{
     width:27.8rem!important;
     position: relative;
   z-index: 13;
   }
   
   }
 
 .tree li:last-child:before{
   border-radius: 0 0 0 5px;
 }
 
 ul.tree>li:first-child::before {
   display:none;
 }
 
 ul.tree>li:first-child::after {
   border-radius:5px 0 0 0;
 }
 
 
 .pl-2{
   padding-left: 2px;
 }
 
 
 
  
 
   .leftBorder{
     border-left:0px solid black;
     justify-content: center;
   }
   .rightBoder{
     border-right: 0px solid black;
   }
 
   input[type=number]::-webkit-inner-spin-button, 
 input[type=number]::-webkit-outer-spin-button { 
   -webkit-appearance: none; 
   margin: 0; 
 }
 .MuiInputBase-root.inputStyle{
         width:10rem!important;
         font-family:"Lato-Regular" !important;
         font-size:1rem;
         border-radius:.4rem;
         height: 2.5rem;
         background:#FFFFFF;
         padding: 0!important;
         border: 1px solid rgba(0, 0, 0, 0.1) !important;
 }
 
 .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
     padding: 0!important;
     height:2.5rem !important;
 }
 
 .lock{
   background: url("../../../public/AppAssets/lock.svg");
   background-size: 100% 100%;
   background-repeat: no-repeat;
   width: 1.5rem;
   height: 1rem;
   border:none;
 
 }
 .unlock{
   background: url("../../../public/AppAssets/unlock.svg");
   background-size: 100% 100%;
   background-repeat: no-repeat;
   width: 1.5rem;
   height: 1rem;
   border: none;
 }
 
 .customizer-buttons .customizer-button{
   margin: 0 1.5rem;
 }
 .sticky-button-header{
   position: sticky;
     top: 0px;
     background-color: #FFFFFF;
     z-index: 100;
     padding: 0 0 1.5rem 0;
     left:1rem;
 }
 .sticky-table-header{
   position: sticky;
     top: 100px;
     background-color: #FFFFFF;
     z-index: 100;
     padding: 0.5rem 0;
 
 }
 .uom{
   background-color: #008CBA !important;
   color:#ffffff !important
   }