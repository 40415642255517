
.dropdown,.multiselectdropdown{
  width:25.5rem;
  box-sizing: border-box;
  background: #FAFAFA;
  border: 1px solid #E6E6E6;
  border-radius: .4rem;
  position: relative;
  height:3.9rem

}

.multiselectdropdown{
  display: flex !important;
  justify-content:flex-start!important;
  position: relative;
  text-transform: none!important;
 

}
.multiselectdropdown_item{
  font-family: 'Lato-Regular'!important;
  font-size: 1.4rem!important;
  color: var(--c1)!important;
  line-height: 1.7rem!important;
  padding: 1rem 0rem;

}

.multiselectdropdown_itemContainer{
position: absolute;
top: 5rem;
z-index: 1111111;
background: var(--c6);
border-radius: 4px;
border: 1px solid rgb(211, 211, 211);
box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
border-radius: 0.4rem;
width: 100%;
height: 30rem;
overflow-y: auto;
overflow-x: hidden;
}
.multiselectdropdown_itemInnerContainer{
  flex-direction: column;
  display: flex;
  width: 22rem;
 
  height: auto!important;
  padding: 1rem;
}
.multiselectdropdown_itemInnerContainer>label{
  display: flex;
  padding:  1rem .5rem;
}
.multiselectdropdown_itemInnerContainer >li{
  padding: 1rem 0rem;
}

select:required:invalid {
color: var(--c4);
}
option[value=""][disabled] {
display: none;
color: var(--c4) !important;
}
option{
font-family: 'Lato-Regular' !important;
font-size: 1.4rem !important;
color:var(--c4) !important;
opacity: 0.8 !important;
line-height: 1.7rem;
}

input[type="checkbox"]:not(.sp-checkbox) {
appearance: none;
border: 1px solid var(--c1);
height: 2rem;
width: 2rem;
vertical-align: bottom;
display: inline-flex;
align-items: center;
justify-content: center;
border-radius: .2rem;
padding: 0;
margin: 0;
position: relative;
}


input[type="checkbox"]:not(.sp-checkbox)::before {
position   : absolute;
display    : block;
top        : 0;
left       : 0;
width      : 2rem;
height     : 2rem;
content    : '';
box-sizing : border-box;


}
input[type="checkbox"]:checked:not(.sp-checkbox)::after {
position   : absolute;

display    : block;

width      : 2rem;
height     : 2rem;
content    : '';
color: var(--c6);

border-radius: .2rem;
background-color:var(--c1);
} 

